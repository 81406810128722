<template>
    <div>
        <v-form id="perfil_form" ref="perfil_form">
            <v-row>
                <v-col cols="12" lg="6">
                    <DashCard title="Perfil" title-icon="account_box">
                            <v-row>
                                <v-col cols="12">
                                    <v-card outlined class="mb-4">
                                        <v-card-text>
                                            <v-label>Empleado/a</v-label>
                                            <PersonaListItem v-model="perfil" />
                                            <v-label>Jefe del Empleado/a</v-label>
                                            <PersonaListItem v-model="jefe" />
                                        </v-card-text>
                                    </v-card>
                                    <v-label>Seleccione perfil de empleado</v-label>
                                    <v-autocomplete autocomplete="off" 
                                        dense solo outlined flat
                                        clearable
                                        v-model="perfil"
                                        :items="editor.empleados" 
                                        label="Perfil de empleado"
                                        prepend-inner-icon="account_box" 
                                        :item-text="(i) => i.first_name + ' ' + i.last_name" 
                                        return-object
                                        :rules="[rules.required, rules.premade]" />
                                </v-col>
                            </v-row>
                    </DashCard>
                </v-col>
                <v-col cols="12" lg="6">
                    <DashCard title="Credenciales" title-icon="vpn_key">
                        <v-row>
                            <v-col cols="12">
                                <v-label>Nombre de usuario</v-label>
                                <v-text-field flat dense solo outlined v-model="usuario.username" label="Nombre de usuario" prepend-inner-icon="account_circle" :rules="[rules.required, rules.username]" />
                            </v-col>
                            <v-col cols="12" lg="6" v-if="!$route.meta.editMode">
                                <v-label>Contraseña</v-label>
                                <v-text-field flat dense solo outlined type="password" v-model="usuario.pass1" label="Contraseña" prepend-inner-icon="vpn_key" :rules="[rules.required, rules.password]" />
                            </v-col>
                            <v-col cols="12" lg="6" v-if="!$route.meta.editMode">
                                <v-label>Repita su contraseña</v-label>
                                <v-text-field flat dense solo outlined type="password" v-model="usuario.pass2" label="Contraseña" prepend-inner-icon="vpn_key" :rules="[rules.required, rules.password, exact_pass]" />
                            </v-col>
                        </v-row>
                    </DashCard>
                </v-col>
                <v-col cols="12" lg="6">
                    <DashCard title="Tipo de Acceso" title-icon="settings">
                        <template v-if="!!grupos && grupos.includes('supers')">
                            <v-alert type="info">
                                Esta opción está deshabilitada para este usuario.
                            </v-alert>
                        </template>
                        <v-row class="ma-0" no-gutters v-else>
                            <v-col cols="12">
                                <v-radio-group v-model="grupos[0]" :rules="[rules.required]">
                                    <v-radio hide-details value="administradores">
                                        <template slot="label">
                                            <div>
                                                <p class="title pa-0 ma-0">Administrador/a</p>
                                                <p class="subtitle pa-0 ma-0">Tendrá acceso a todos los paneles de la plataforma</p>
                                            </div>
                                        </template>
                                    </v-radio>
                                    <v-radio hide-details value="empleados">
                                        <template slot="label">
                                            <div>
                                                <p class="title pa-0 ma-0">Empleado/a</p>
                                                <p class="subtitle pa-0 ma-0">Solo podrá atender SIAs, Incidentes, Tareas Críticas y Compromisos de seguridad dentro de la plataforma.</p>
                                            </div>
                                        </template>
                                    </v-radio>
                                </v-radio-group>
                            </v-col>
                        </v-row>
                    </DashCard>
                </v-col>
                <v-col cols="12">
                    <DashCard :actions="true" actions-align="right">
                        <template v-slot:actions>
                            <v-btn depressed large text color="primary" :to="{ name: 'dashboard_usuarios' }" exact>
                                <v-icon>close</v-icon>
                                <span class="ml-2 subtitle-2">Descartar</span>
                            </v-btn>
                            <v-btn depressed large color="primary" type="submit" @click="uploadUser">
                                <v-icon>done</v-icon>
                                <span class="ml-2 subtitle-2">Guardar</span>
                            </v-btn>
                        </template>
                    </DashCard>
                </v-col>
            </v-row>
        </v-form>
        <Message ref="dialog" />
        <Message ref="success_dialog" @onClose="$router.push({ name: 'dashboard_usuarios' })" />
    </div>
</template>

<script>
import { axiosHelper, messages, valid_username, valid_email, valid_required, valid_password } from '@/helpers';
import DashCard from '@/shared/components/DashCard'
import Message from '@/shared/components/Message'
import CommonDialog from '@/shared/components/CommonDialog'
import PersonaListItem from '@/dash/components/PersonaListItem'

export default {
    components: {
        DashCard,
        Message,
        CommonDialog,
        PersonaListItem
    },
    data: () => ({
        user_editor_type: 0,
        usuario: {
            perfil: {}
        },
        editor: {},
        rules: {
            premade: value => (!!value && Object.keys(value).length > 0) || "Debe seleccionar un perfil",
            required: value => valid_required(value),
            username: value => valid_username(value),
            password: value => valid_password(value),
            email: value => valid_email(value),
        }
    }),
    computed: {
        editMode() {
            return this.$route.meta.editMode || false;
        },
        exact_pass() {
            return (this.usuario.pass1 == this.usuario.pass2) || "Las contraseñas deben coincidir";
        },
        perfil: {
            get() {
                this.usuario.perfil = this.usuario.perfil || {};
                return this.usuario.perfil;
            },
            set(value) {
                this.usuario.perfil = value;
            }
        },
        grupos: {
            get() {
                this.usuario.groups = this.usuario.groups || [];
                return this.usuario.groups;
            },
            set(value) {
                this.usuario.groups = value;
            }
        },
        jefe() {
            if(!this.perfil || !this.editor.empleados) return null;
            return this.editor.empleados.filter(x => x.id == this.usuario.perfil.jefe)[0];
        }
    },
    methods: {
        success() {
            this.$refs.success_dialog.show(messages.titles['success_generico'], messages.texts[this.editMode ? 'success_editar_usuario' : 'success_new_usuario'], 'success');
        },
        error(response) {
            this.$refs.dialog.show(messages.titles['error_generico'], messages.texts['error_desconocido'], 'error');
        },
        uploadUser(e) {
            e.preventDefault();  
            let usuario = { ...this.usuario };
            usuario.password = this.usuario.pass1;

            delete usuario.pass1;
            delete usuario.pass2;

            if(!this.$refs.perfil_form.validate()) {
                this.$refs.dialog.show(messages.titles['error_generico'], messages.texts['error_check_fields'], 'error');
                return;
            }

            if(this.editMode) {
                axiosHelper.put('usuarios/' + this.$route.params.id + '/', usuario)
                    .then(() => this.success())
                    .catch(response => this.error(response));
            } else {
                axiosHelper.post('usuarios/', usuario)
                    .then(() => this.success())
                    .catch(response => this.error(response));
            }
        }
    },
    mounted() {
        let text = "Crear usuario";

        if(this.editMode) {
            text = "Editar usuario";
            
            axiosHelper.get('usuarios/' + this.$route.params.id + '/')
                .then(response => {
                    this.usuario = response.data;
                });
        }

        axiosHelper.get('usuarios/editor/')
            .then(response => this.editor = response.data);

        this.$emit('title', { text: text, icon: "account_circle" })
    }
}
</script>